import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';
import DateUtil from 'src/app/util/date-util';
import { Router } from '@angular/router';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import StaticParams from 'src/app/util/static-params';
import StorageUtil from 'src/app/util/storage-util';
import { GoogleAnalyticsService } from 'src/app/services/googleanalytics.service';

@Component({
    selector: 'app-modal-profile',
    templateUrl: './modal-profile.component.html',
    styleUrls: ['./modal-profile.component.scss'],
    standalone: false
})
export class ModalProfileComponent implements OnInit {

  public isDarkMode = false
  public userInfo: any;
  public activePlanoDescription: any;
  public userBasicInfo: any = {}
  public showPaymentAssociation: boolean = false;
  public codigoPagamento: string = '';
  public btnLoading: boolean = false;
  public btnRemoveLoading: string = ''
  public alphaItens: any = [
    { title: 'Pasta Alpha', icon: 'far fa-folder-open', url: 'https://drive.google.com/drive/u/0/folders/1x0xSWj_TLPBKKdsp8VNQ20CUE1iWuzNm' },
  ]

  constructor(
    private _viewOptionsService: ViewOptionsService,
    private _authService: AuthService,
    private _userService: UserService,
    public activeModal: NgbActiveModal,
    private _toastr: ToastrService,
    private _modalService: NgbModal,
    private _paymentService: PaymentService,
    private _router: Router,
    private _googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);

    this.userInfo = this._authService.getDecodedJwt();
    this.userBasicInfo = this._authService.getBasicUserInfo();
    this.userBasicInfo.photo = this.userBasicInfo.photo == 'null' ? 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png' : this.userBasicInfo.photo
    this.userBasicInfo.name = this.userBasicInfo.name == 'null' ? StorageUtil.getActiveWalletName().nome : this.userBasicInfo.name

    if (this._authService.isAlphaUser()) {
      this.userInfo.alpha.status = "Ativo"
    } else if (this.userInfo.alpha) {
      this.userInfo.alpha.status = "Inativo"
    }
  }

  getPlanoAlphaDescription() {
    if (!this.userInfo.alpha) { return null }

    let planosObject = StaticParams.planosAlpha
    let keys = Object.keys(planosObject)
    for (let i in keys) {
      let row = planosObject[keys[i]]
      if (row.cod_external == this.userInfo.alpha.cd_plano) {
        return row.nome
      }
    }
    return null
  }

  clickShowPaymentAssociation() {
    this.showPaymentAssociation = !this.showPaymentAssociation;
  }

  sendPayment() {
    this.btnLoading = true;
    if (this.codigoPagamento == '') {
      this._toastr.error('Favor, informar um código de pagamento válido!');
      this.btnLoading = false;
      return;
    }

    let isnum = /^\d+$/.test(this.codigoPagamento);
    if (!isnum) {
      this._toastr.error('O código de pagamento é composto somente por números!');
      this.btnLoading = false;
      return;
    }

    let payment_code = this.codigoPagamento

    this._paymentService.linkPayment(payment_code, false).subscribe({
      next: (res) => {
        this._toastr.success(res.message);
        this._googleAnalyticsService.trackEvent('ADD_PAYMENT_CODE', 'Vincular plano', 'PLANO')

        this._userService.getUser().subscribe({
          next: (usuario) => {
            this._authService.setSession(usuario);
            this._toastr.info('Aguarde enquanto recarregamos a página!');
            setTimeout(() => {
              this.document.location.href = '/carteira'
            }, 3000);
          },
          error: (err) => { }
        })
      },
      error: (err) => {
        this.btnLoading = false;
      }
    })
  }

  sendRemovePayment(paymentCode: string) {
    const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions)
    modalRef.componentInstance.modalProps = {
      type: 'confirm',
      message: `Tem certeza que deseja excluir o pagamento <strong>${paymentCode}</strong> de sua conta?`,
      okText: 'Confirmar',
      cancelText: 'Cancelar'
    }

    modalRef.result.then((result) => {
      if (result.type) {
        this.btnRemoveLoading = paymentCode;
        this._paymentService.unlinkAlphaPayment(paymentCode).subscribe({
          next: (message) => {
            this._toastr.success(message.message);
            this._googleAnalyticsService.trackEvent('REMOVE_PAYMENT_CODE', 'Remover vínculo de plano', 'PLANO')

            this._userService.getUser().subscribe({
              next: (usuario) => {
                this._authService.setSession(usuario);
                this._toastr.info('Aguarde enquanto recarregamos a página!');
                this.btnRemoveLoading = '';
                setTimeout(() => {
                  this.document.location.href = '/carteira'
                }, 3000);
              },
              error: (err) => { }
            })
          },
          error: (err) => {
            this.btnRemoveLoading = '';
          }
        })
      }
    })
  }

  isValidPaymentCode(paymentDate: any) {
    paymentDate = new Date(paymentDate)
    paymentDate.setMonth(paymentDate.getMonth() + 12);
    return DateUtil.dateToString(paymentDate) >= DateUtil.dateToString(new Date())
  }

  goToPgAlpha() {
    this._router.navigate(['/alpha']);
    this.activeModal.close()
  }

  removeUserAccount() {
    const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
    modalRef.componentInstance.modalProps = {
      type: 'prompt',
      message: `<strong>ATENÇÃO!</strong><br>
      Todos os seus dados serão excluidos do sistema. Essa operação é <strong>irreversível</strong>!<br>
      Para confirmar, escreva "Excluir minha conta", e clique em Confirmar`,
      okText: 'Confirmar',
      cancelText: 'Cancelar'
    }

    modalRef.result.then((result) => {
      if (result.type) {
        if (result.prompt == "Excluir minha conta") {
          this._toastr.warning("Aguarde enquanto excluímos sua conta. Ao finalizar, você será deslogado automaticamente.")
          this._userService.removeAccount().subscribe({
            next: () => {
              this._googleAnalyticsService.trackEvent('DELETE_ACCOUNT', 'Exclusão total da conta', 'CONTA')
              this._toastr.success("Conta excluída com sucesso.")
              this._authService.logout()
            },
            error: (err) => { }
          })
        } else {
          this._toastr.warning("Sua conta não foi excluída, digite 'Excluir minha conta'.")
        }
      }
    });
  }
}

