/* src/app/components/modal-settings/modal-settings.component.scss */
ul {
  text-align: center;
}
.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
input {
  text-align: center;
}
h2 {
  color: #31bdbd;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #ffffff;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #ffffff;
}
select {
  text-align: center;
  text-align-last: center;
}
option {
  text-align: left;
}
::ng-deep .modal-settings-dialog .modal-dialog {
  max-width: 45rem;
}
/*# sourceMappingURL=modal-settings.component.css.map */
