<div class="modal-content" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default'">

    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-notification">Minha Conta</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
            <span class="text-white" aria-hidden="true">x</span>
        </button>
    </div>

    <div class="modal-body py-3 text-center text-white">
        <!-- Perfil do Usuário -->
        <img src='{{ userBasicInfo?.photo }}' class="image-profile">

        <div class="pt-4">
            <div class="text-white"> {{ userBasicInfo.name }} </div>
            <div class="text-gray"> {{ userBasicInfo.email }} </div>
            <button class="btn text-red pt-0 mt-0 text-small" (click)="removeUserAccount()">
                <i class="fa fa-eraser"></i> Excluir Conta
            </button>
        </div>

        <!-- Usuario Alpha -->
        <div *ngIf='userInfo.alpha'>
            <div class="pt-3 text-white">
                <span>Plano</span>
                <div class="text-gray text-small">
                    <span> {{ getPlanoAlphaDescription() }}</span><br>
                    <span class="text-small"> {{ userInfo.alpha.cd_plano }}</span><br>
                    <span class="text-small">Vence em: {{ userInfo.alpha.data_vencimento | date:'dd/MM/yyyy'
                        }}</span><br>
                    <span class="text-small">Status: {{ userInfo.alpha.status }}</span><br>
                </div>
            </div>

            <div *ngIf="userInfo.alpha.pagamentos" class="mt-3">
                <span>Pagamentos Vinculados</span>
                <div *ngFor="let pagamento of userInfo.alpha.pagamentos" class="text-gray mt-2 text-small">
                    <span class="pr-1">{{ pagamento.data_pagamento | date:'dd/MM/yyyy' }} |</span>
                    <span class="pr-1">{{ pagamento.cd_pagamento }} |</span>
                    <span class="pr-1">R${{ pagamento.vl_pago | number }} </span>
                    <span *ngIf="isValidPaymentCode(pagamento.data_pagamento)" class="text-red cursor-pointer"
                        (click)="sendRemovePayment(pagamento.cd_pagamento)">
                        <i class="fas fa-trash-alt" *ngIf="btnRemoveLoading.indexOf(pagamento.cd_pagamento) < 0 "> </i>
                        <i class="fa fa-spinner fa-spin"
                            *ngIf="btnRemoveLoading.indexOf(pagamento.cd_pagamento) >= 0 "></i>
                    </span>
                    <span *ngIf="!isValidPaymentCode(pagamento.data_pagamento)" class="text-gray">
                        <i class="fas fa-trash-alt"> </i>
                    </span>
                </div>
            </div>

            <div class="mt-3 text-white">
                Recursos Alpha
                <ul class="navbar-nav mb-md-3">
                    <li class="text-gray" *ngFor="let alphaItem of alphaItens">
                        <a class="text-gray text-small" href="{{alphaItem.url}}" target="_blank">
                            <i class="{{alphaItem.icon}}"></i>
                            {{alphaItem.title}}
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="text-gray" *ngIf='!userInfo.alpha'>
            Usuário Alpha não identificado!
            <br>
            <br>
            <button type="button" class="btn btn-secondary" (click)="goToPgAlpha()">
                Planos
            </button>
        </div>

        <div class="vincular-alpha">
            <button id="adicionar-pagamento" type="button" class="btn btn-secondary"
                (click)="clickShowPaymentAssociation()" [disabled]="btnLoading">Vincular novo pagamento</button>
            <div class="vincular-alpha-form" *ngIf='showPaymentAssociation'>
                <div>Código de pagamento: </div>
                <input class="text-center" maxlength="18" type="text" [(ngModel)]="codigoPagamento" />
                <button type="button" class="btn btn-primary" (click)="sendPayment()" [disabled]='btnLoading'>
                    <i class="fa fa-spinner fa-spin" *ngIf='btnLoading'></i>
                    Enviar
                </button>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal"
            (click)="activeModal.close('Close click')">Fechar</button>
    </div>

</div>