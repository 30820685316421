/* src/app/components/modal-pix/modal-pix.component.scss */
::ng-deep .modal-pix .modal-dialog {
  max-width: 40rem;
  font-size: 12px;
}
#qr-code {
  overflow-wrap: break-word;
}
.qr-code-base64 {
  width: 10rem;
}
.btn-link {
  cursor: pointer;
}
/*# sourceMappingURL=modal-pix.component.css.map */
