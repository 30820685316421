<div class="modal-content" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default' ">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-notification">Lançar Operação</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span class="text-white" aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body py-3 text-center text-white">
        <div class="row">
            <div class="col-md-12">
                <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Categoria</h5>
                <ng-select [clearable]="false" (change)="handleCategories()" id="operation-categoria" name="categoria"
                    [(ngModel)]="categoriaSelecionada">
                    <ng-option *ngFor="let categoria of categorias" [value]="categoria">{{ categoria }}</ng-option>
                </ng-select>
                <h5 *ngIf="categoriaSelecionada == categoriaEnum.rfixa" class="mt-4 text-white">O seu ativo será atualizado automaticamente. Não é necessário o lançamento de saldo!</h5>
                <h5 *ngIf="categoriaSelecionada == categoriaEnum.outros" class="mt-4 text-white">O saldo do seu ativo deverá ser atualizado manualmente. Sugerimos <a class="link-saldo" href="https://www.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas#h.k2lzez15fgpy" target="_blank">lançar operações com o evento "Saldo"</a> ao fim de cada mês.</h5>
            </div>

            <div *ngIf="categoriaSelecionada != null" class="col-md-6">
                <div class="col-md-12">
                    <h5 class="mt-4 text-gray d-inline-block" triggers="mouseenter:mouseleave"
                        ngbTooltip="Selecione o ativo">Ativo</h5>
                    <button *ngIf="categoriaSelecionada == categoriaEnum.rfixa || categoriaSelecionada == categoriaEnum.outros" 
                        class="btn btn-simple btn-sm p-0 m-0" (click)="openModalAddSimple()" ngbTooltip="Criar novo ativo">
                        <i class="fas fa-plus-square"></i>
                    </button>
                    <div [ngSwitch]="true">
                        <!--
                        Fiz este uso diferente do ngSwitch=true para poder fazer o case com multiplas condicoes
                        -->
                        <ng-select id="select-ativo-input"
                                   [items]="inputOptions$ | async"
                                   [minTermLength]="1"
                                   placeholder="{{getCategoryPlaceHolder()}}"
                                   typeToSearchText="{{getCategoryPlaceHolder()}}"
                                   [clearable]="false"
                                   *ngSwitchCase="[categoriaEnum.cvm, categoriaEnum.brasil, categoriaEnum.internacionais, categoriaEnum.criptomoeda].indexOf(categoriaSelecionada) > -1"
                                   class="select-option" (change)="handleAsset()"
                                   [typeahead]="tickerInput$"
                                   [(ngModel)]="operationRow.ativo">
                        </ng-select>
                        <ng-select id="select-ativo-input" placeholder="{{getCategoryPlaceHolder()}}"
                                   *ngSwitchCase="categoriaSelecionada === categoriaEnum.rfixa || categoriaSelecionada === categoriaEnum.outros"
                                   class="select-option" (change)="handleOtherAsset()" [clearable]="false"
                                   [(ngModel)]="operationRow.ativo">
                            <ng-option *ngFor="let ativo of ativosSelect" [value]="ativo.ativo">{{ ativo.ativo }}
                            </ng-option>
                        </ng-select>
                        <input id="select-ativo-input" placeholder="{{getCategoryPlaceHolder()}}"
                                   *ngSwitchCase="categoriaSelecionada === categoriaEnum.opcoes"
                                   class="select-option" (change)="handleAsset()" [(ngModel)]="operationRow.ativo"/>
                        <ng-select id="select-ativo-input" placeholder="{{getCategoryPlaceHolder()}}"
                                   *ngSwitchDefault class="select-option" (change)="handleAsset()" [clearable]="false"
                                   [(ngModel)]="operationRow.ativo">
                            <ng-option *ngFor="let ativo of ativosSelect" [value]="ativo">{{ ativo['ticker'] }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Data</h5>
                    <div class="input-group col-md-13 justify-content-center">
                        <input class="operation-edit-date" name="date" type="text" inputmode="numeric" mask="d0/M0/0000" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" placeholder="dd/mm/aaaa" [(ngModel)]="operationRow.date">
                        <input [hidden]="true" name="ngbdate" type="text" ngbDatepicker #op_date="ngbDatepicker" (dateSelect)="onDatePickerChange($event)">
                        <button class="op-date-calendar fas fa-calendar" (click)="op_date.toggle()" type="button"></button>
                    </div>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Evento <a href="https://web.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas" target="_blank"><span ngbTooltip="Clique aqui para acessar o FAQ com explicação completa de todos os eventos disponíveis para lançamento."><i class="fas fa-question-circle"></i></span></a></h5>
                    <ng-select class="select-option" [clearable]="false" name='evento'
                        [(ngModel)]="operationRow.evento">
                        <ng-option *ngFor="let event of events" [value]="event['event']">{{event['show']}}</ng-option>
                    </ng-select>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Quantidade</h5>
                    <input [disabled]="categoriaSelecionada == categoriaEnum.rfixa || categoriaSelecionada == categoriaEnum.outros" type="number" name='qtd' [(ngModel)]="operationRow.qtd">
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Preço</h5>
                    <input *ngIf="categoriaSelecionada != categoriaEnum.criptomoeda" type="text" name='preco' [(ngModel)]="operationRow.preco" currencyMask>
                    <input *ngIf="categoriaSelecionada == categoriaEnum.criptomoeda" type="number" name='preco' [(ngModel)]="operationRow.preco">
                </div>
            </div>

            <div *ngIf="categoriaSelecionada != null" class="col-md-6">
                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" ngbPopover="Taxas referente a operação" triggers="mouseenter:mouseleave">Taxas (opcional)</h5>
                    <input type="text" name='taxas' [(ngModel)]="operationRow.taxas" currencyMask>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">
                        Corretora
                    </h5>
                    <input class="corretora-upper" type="text" name='corretora' [(ngModel)]="operationRow.corretora">
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">IRRF (opcional)</h5>
                    <input type="text" name='irrf' [(ngModel)]="operationRow.irrf" currencyMask>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Moeda (opcional)</h5>
                    <ng-select class="select-option" [clearable]="false" id="operation-edit-moeda" name="moeda"
                        [(ngModel)]="operationRow.moeda">
                        <ng-option *ngFor="let currency of currencys" [value]="currency">{{ currency }}</ng-option>
                    </ng-select>
                </div>

                <div class="col-md-12">
                    <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">
                        Observação (opcional)
                    </h5>
                    <input type="text" id='operation-edit-observacao' name='observacao'
                        [(ngModel)]="operationRow.observacao">
                </div>

            </div>

            <div class="col-md-12" *ngIf="(categoriaSelecionada === categoriaEnum.rfixa
            || categoriaSelecionada === categoriaEnum.outros) && operationRow.ativo">
                <h5 class="text-gray rf-info-header">Informações do ativo selecionado</h5>
                <ul class="rf-info-list">
                    <li class="text-gray">Tipo de ativo: {{selectedOtherInfo.setor}}</li>
                    <li class="text-gray">Índice: {{selectedOtherInfo.subsetor}}</li>
                    <li class="text-gray">Classe: {{selectedOtherInfo.classe}}</li>
                    <li *ngIf="categoriaSelecionada === categoriaEnum.rfixa && !selectedOtherInfo.annual_additional_percentage"
                        class="text-gray" >% Índice: {{selectedOtherInfo.index_percentage}}%</li>
                    <li *ngIf="categoriaSelecionada === categoriaEnum.rfixa && !selectedOtherInfo.index_percentage"
                        class="text-gray">% ao ano: {{selectedOtherInfo.annual_additional_percentage}}%</li>
                </ul>
            </div>
            <div  *ngIf="categoriaSelecionada === categoriaEnum.rfixa && operationRow.evento == 'V'">
                <h5 class="text-gray">
                    <i class="fas fa-info-circle pt-1"></i>
                    Caso esteja realizando o resgate completo do seu investimento e o valor do resgate não esteja igual
                    ao apresentado no portfólio, realize a operação de VENDA com o valor real do resgate e em seguida
                    adicione uma nova operação com o evento BAIXA para que possamos encerrar sua posição
                </h5>
            </div>

            <div *ngIf="categoriaSelecionada == categoriaEnum.internacionais" class="col-md-6 mt-4">
                <h5 class="text-gray" triggers="mouseenter:mouseleave">Lançar ativo com código de mercado
                    <span ngbTooltip="O código de mercado ajuda a diferenciar ativos de bolsas diferentes, como NYSE:META e NYSEARCA:META, em alguns casos ele é obrigatório devido ao mesmo nome de ativo estar presente em mais de um mercado."><i class="fas fa-question-circle"></i></span>
                </h5>
                <span>Não</span>
                <label class="custom-toggle mb-0 mx-2">
                    <input type="checkbox" [disabled]="isMarketCodMandatory" name="marketcodOption" [checked]="addWithMarketCod"
                        (change)="addWithMarketCod = !addWithMarketCod">
                    <span class="custom-toggle-slider rounded-circle"></span>
                </label>
                <span>Sim</span>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary mr-auto" (click)="resetOperation()">Limpar</button>
        <button type="button" class="btn btn-link text-white" data-dismiss="modal"
            (click)="activeModal.close(false)">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="addOperation()" [disabled]="btnLoading">
            <i class="fa fa-spinner fa-spin" *ngIf='btnLoading'></i>
            Salvar
        </button>
    </div>

</div>