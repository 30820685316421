/* src/app/components/modal-alocacao/modal-alocacao.component.scss */
::ng-deep .modal-alocacao .modal-dialog {
  max-width: 390px;
  font-size: 12px;
}
input {
  width: 140px;
}
.edit-row {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
/*# sourceMappingURL=modal-alocacao.component.css.map */
