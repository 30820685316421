/* src/app/components/modal-termos-uso/modal-termos-uso.component.scss */
#termos-uso-text-div {
  max-height: 600px;
  overflow: auto;
}
p {
  font-weight: 500;
}
.btn {
  width: 120px;
}
/*# sourceMappingURL=modal-termos-uso.component.css.map */
