/* src/app/components/modal-table/modal-table.component.scss */
::ng-deep .modal-table .modal-dialog {
  max-width: 65rem;
  font-size: 12px;
}
.unmarked {
  color: rgb(230, 230, 230);
}
td {
  white-space: pre-wrap;
  word-wrap: break-word;
}
/*# sourceMappingURL=modal-table.component.css.map */
