/* src/app/components/modal-fundamentos/modal-fundamentos.component.scss */
::ng-deep .modal-fundamentos .modal-dialog {
  max-width: 980px;
  font-size: 12px;
}
.nav-link {
  font-weight: 600;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
/*# sourceMappingURL=modal-fundamentos.component.css.map */
