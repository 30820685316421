<div class="modal-content" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default'">

    <div class="modal-header pb-0">
        <h6 class="modal-title" id="modal-title-notification">{{ modalProps.title }}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="buttonCancel()">
            <span class="text-white" aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body pt-0 mt-0 ml-1 mr-1 text-white">
        <div [innerHTML]="modalProps.message"> </div>
    </div>

    <div class="row justify-content-center" *ngIf="modalProps.type == 'prompt'">
        <input class="pt-0" type="text" style='width: 200px' id='prompt-message' name='message'
            [(ngModel)]="modalProps.prompt" (keydown.enter)="buttonOk()">
    </div>

    <div class="modal-footer">
        <button type="button" *ngIf="modalProps.type != 'info'" class="btn btn-link text-white" data-dismiss="modal" (click)="buttonCancel()">
            {{ modalProps.cancelText }}
        </button>
        <button type="button" class="btn btn-primary" (click)="buttonOk()">{{ modalProps.okText }}</button>
    </div>

</div>