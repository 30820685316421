<div class="modal-content">
    <div class="modal-header justify-content-center pb-0">
        <h2 class="modal-title">{{modalProps.title}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left pt-3 overflow-auto">

        <div class="table-responsive pl-1 pr-1" [ngStyle]="setMaxModalHeigth()">
            <table class="tb-nowrap tb-striped tb-border table-hover align-items-center">

                <thead class="thead-light">
                    <th *ngFor="let col of colValues; let i = index" [ngClass]="dynamicCss.tablePadding('1')">
                        <span class="row-td-resume">
                            {{ col }}
                        </span>
                    </th>

                    <th [ngClass]="dynamicCss.tablePadding('1')" *ngIf="btnOptions.length > 0">
                        <span class="row-td-resume">
                            Selecione
                        </span>
                    </th>

                </thead>

                <tbody>
                    <tr *ngFor="let row of modalProps.data">
                        <td *ngFor="let col of colKeys; let i = index"
                            [ngClass]="dynamicCss.formatNumbersClass(row[col], 'red', 'dark')">

                            <span class="row-td-resume font-weight-bold" *ngIf="resultDecimals[i] == 'link' ">
                                <a [href]="numberValueToString(row[col], i)" target="_blank">Link</a>
                            </span>

                            <span class="row-td-resume font-weight-bold" *ngIf="resultDecimals[i] != 'link'">
                                {{ numberValueToString(row[col], i) }}
                            </span>

                        </td>

                        <td *ngIf="resultDecimals[i] != 'link' && btnOptions.length > 0" class="text-center">

                            <button *ngIf="btnOptions.indexOf('select') >= 0" ngbTooltip="Selecionar"
                                class="btn btn-simple border btn-sm" (click)="selectRow(row)">
                                <i class="fas fa-check text-green"></i>
                            </button>

                            <button
                                *ngIf="btnOptions.indexOf('operation') >= 0 && (row.hash && !userEventsLocal[row.hash] || userEventsLocal[row.hash].done != true)"
                                ngbTooltip="Adicionar em operações" class="btn btn-simple border btn-sm"
                                (click)="addOperation(row)">
                                <i class="fas fa-plus-square text-blue"></i>
                            </button>
                            <button
                                *ngIf="btnOptions.indexOf('earning') >= 0 && (row.hash && !userEventsLocal[row.hash] || userEventsLocal[row.hash].done != true)"
                                ngbTooltip="Adicionar em proventos" class="btn btn-simple border btn-sm"
                                (click)="addEarning(row)">
                                <i class="fas fa-coins"></i>
                            </button>

                            <button
                                *ngIf="btnOptions.indexOf('done') >= 0 && (row.hash && !userEventsLocal[row.hash] || userEventsLocal[row.hash].done != true)"
                                ngbTooltip="Marcar como OK" class="btn btn-simple border btn-sm"
                                (click)="done(row)">
                                <i class="fas fa-check unmarked"></i>
                            </button>
                            <button
                                *ngIf="btnOptions.indexOf('done') >= 0 && (row.hash && userEventsLocal[row.hash] && userEventsLocal[row.hash].done == true)"
                                ngbTooltip="OK" class="btn btn-simple border btn-sm"
                                (click)="undone(row)">
                                <i class="fas fa-check text-green"></i>
                            </button>

                            <button *ngIf="btnOptions.indexOf('del') >= 0" ngbTooltip="Excluir" (click)="delete(row)"
                                class="btn btn-simple border btn-sm">
                                <i class="fas fa-trash-alt text-red"></i>
                            </button>

                            <span></span>
                        </td>

                    </tr>
                </tbody>

            </table>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close(false)">
            Fechar
        </button>
    </div>
</div>