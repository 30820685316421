<div class="modal-content">
    <div class="modal-header justify-content-center">
        <h2 class="modal-title">Editar Carteira </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left pt-0 pb-0 overflow-auto">

        <div class="row mt-0 mr-0 ml-0 ">
            <div class="table-responsive pb-2" style="width: 320px;">
                <table class="tb-nowrap tb-striped tb-border table-hover text-center">
                    <th colspan="2">Nome Carteira</th>
                    <th>Peso Carteira (%)</th>
                    <tbody>
                        <tr>
                            <td> <input style="width: 20px;" nz-input class="edit-row"
                                    [(ngModel)]="alocacaoWallet.numero_carteira" /> </td>
                            <td> <input nz-input class="edit-row" [(ngModel)]="alocacaoWallet.nome_carteira" /> </td>
                            <td> <input nz-input class="edit-row" [(ngModel)]="alocacaoWallet.peso_carteira" /> </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="small pt-1 overflow-auto jspreadsheet-maxheight" id="spreadsheet" #spreadsheet></div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-info mr-auto" data-dismiss="modal" (click)="table.insertRow()"
            ngbTooltip="Adicionar Linhas">
            &nbsp;<i class="fas fa-plus-square pt-1"></i>&nbsp;
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="activeModal.close(false)">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="save()">
            Salvar
        </button>
    </div>
</div>