<div class="modal-content" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default' ">

    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-notification">Configurações de Cálculos</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span class="text-white" aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body py-0 text-center text-white">
        <i class="ni ni-settings ni"></i>

        <div class="row">
            <div class="col-md-6">
                <h3 class="text-white font-weight-bolder pt-4">OPERAÇÕES - Cálculos</h3>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray" ngbPopover="Calcular pm e lucros por ativo e corretora"
                        triggers="mouseenter:mouseleave">Cálculo por corretora</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='calc_por_corretora' [checked]="userSetting?.calc_por_corretora"
                            (change)="userSetting.calc_por_corretora = !userSetting.calc_por_corretora">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray" ngbPopover="Moeda base de cálculos da planilha"
                        triggers="mouseenter:mouseleave">Moeda base de cálculos</h4>
                    <label class="mb-0 mx-2">
                        <select style='width: 90px' type="text" id='moeda_base' name='moeda_base' maxlength="3"
                            [(ngModel)]="userSetting.moeda_base">
                            <option *ngFor="let currency of currencys">{{currency}}</option>
                        </select>
                    </label>
                </div>

                <div class="col-md-12 d-none">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Unifica compras e vendas no mesmo dia, trata daytrades não lançados corretamente, trata inversões de posição (comprada -> vendida e vice-versa) separando as operações para zerar a posição antes da inversão"
                        triggers="mouseenter:mouseleave">Tratar operações</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='tratar_operacoes'>
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray" ngbPopover="Quantidade de dias para a data de liquidação do PTAX"
                        triggers="mouseenter:mouseleave">Qtd de dias de liquidação PTAX (até 31/12/2023 - Lei 14754/2023)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" id='dia_liq_ptax' name='dia_liq_ptax'
                            [(ngModel)]="userSetting.dia_liq_ptax">
                    </label>
                </div>


                <h3 class="text-white font-weight-bolder pt-4">DARF - Cálculos</h3>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Meses com vendas abaixo de 20k usa o saldo (lucro - prejuizo) no resultado do mês"
                        triggers="mouseenter:mouseleave">Sempre usar o saldo no resultado mensal</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='usar_saldo_vd20k' [checked]="userSetting?.usar_saldo_vd20k"
                            (change)="userSetting.usar_saldo_vd20k = !userSetting.usar_saldo_vd20k">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Considera operações DayTrade no valor de alienações mensais até R$ 20.000"
                        triggers="mouseenter:mouseleave">Vendas de daytrade no limite 20k</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='dt_vd20k' [checked]="userSetting?.dt_vd20k"
                            (change)="userSetting.dt_vd20k = !userSetting.dt_vd20k">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Considera alienação de outros ativos não ações no valor de alienações mensais até 20k. (BDR, ETF, FIP, etc)"
                        triggers="mouseenter:mouseleave">Vendas de outras classes no limite 20k</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='outros_vd20k' [checked]="userSetting?.outros_vd20k"
                            (change)="userSetting.outros_vd20k = !userSetting.outros_vd20k">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Considera alienação de opções no valor de alienações mensais até 20k. (Opções e Execuções de Opções)"
                        triggers="mouseenter:mouseleave">Vendas de opções no limite 20k</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='opcoes_vd20k' [checked]="userSetting?.opcoes_vd20k"
                            (change)="userSetting.opcoes_vd20k = !userSetting.opcoes_vd20k">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <a href="http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=120739"
                        target="_blank">
                        <h4 class="mt-1 text-gray"
                            ngbPopover="Vendas de SUBSCRIÇÃO DE FII (tributação 20%) e não (tributação em 15%) até 28/09/2021"
                            triggers="mouseenter:mouseleave"
                            href="http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=120739"
                            target="_blank"> Venda de subscrição de FII tributar 20% (até 28/09/2021)
                        </h4>
                    </a>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" checked name='vsub_FII' [checked]="userSetting?.vsub_FII"
                            (change)="userSetting.vsub_FII = !userSetting.vsub_FII">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <a href="http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=120739"
                        target="_blank">
                        <h4 class="mt-1 text-gray"
                            ngbPopover="Vendas de SUBSCRIÇÃO (AÇÃO e FII) com direito a isenção de 20k até 28/09/2021"
                            triggers="mouseenter:mouseleave">Venda subscrição de FII como isento (até 28/09/2021)
                        </h4>
                    </a>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='vsub_isento' [checked]="userSetting?.vsub_isento"
                            (change)="userSetting.vsub_isento = !userSetting.vsub_isento">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Considerar operações e isenção em BDR como AÇÕES: marcado -> considerar / desmarcado -> não considerar"
                        triggers="mouseenter:mouseleave">Considerar BDR nas isenções</h4>
                    <span>Não</span>
                    <label class="custom-toggle mb-0 mx-2">
                        <input type="checkbox" name='bdr_like_acao' [checked]="userSetting?.bdr_like_acao"
                            (change)="userSetting.bdr_like_acao = !userSetting.bdr_like_acao">
                        <span class="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <span>Sim</span>
                </div>
            </div>


            <div class="col-md-6">
                <h3 class="text-white font-weight-bolder pt-4">DARF - Parâmetros iniciais</h3>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray" ngbPopover="Ano de inicio dos cáclulos da darf"
                        triggers="mouseenter:mouseleave">Ano de início dos calculos DARF (ex. 2020)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" id='darf_inicio' name='darf_inicio'
                            [(ngModel)]="userSetting.darf_inicio">
                    </label>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Prejuízo acumulado de operações normais até o ano anterior ao ano de inicio da DARF"
                        triggers="mouseenter:mouseleave">Prejuízo normal até inicio DARF (negativo)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" step='0.50' id='tributavel_normal'
                            name='tributavel_normal' [(ngModel)]="userSetting.tributavel_normal">
                    </label>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Prejuízo acumulado de operações daytrade até o ano anterior ao ano de inicio da DARF"
                        triggers="mouseenter:mouseleave">Prejuízo daytrade até inicio DARF (negativo)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" step='0.50' id='tributavel_daytrade'
                            name='tributavel_daytrade' [(ngModel)]="userSetting.tributavel_daytrade">
                    </label>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Prejuízo acumulado de operações FIIs até o ano anterior ao ano de inicio da DARF"
                        triggers="mouseenter:mouseleave">Prejuízo FII até inicio da DARF (negativo)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" step='0.50' id='tributavel_fii' name='tributavel_fii'
                            [(ngModel)]="userSetting.tributavel_fii">
                    </label>
                </div>

                <div class="col-md-12">
                    <h4 class="mt-1 text-gray"
                        ngbPopover="Saldo a pagar menor que R$10,00 até o ano anterior ao ano de inicio da DARF"
                        triggers="mouseenter:mouseleave">Saldo de DARF a pagar até inicio DARF (positivo)</h4>
                    <label class="mb-0 mx-2">
                        <input style='width: 90px' type="number" step='0.50' id='darf_total' name='darf_total'
                            [(ngModel)]="userSetting.darf_total">
                    </label>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary mr-auto" (click)="resetDefault()">Padrão</button>
        <button type="button" class="btn btn-link text-white" data-dismiss="modal"
            (click)="activeModal.close(false)">Fechar</button>
        <button type="button" class="btn btn-success" (click)="updateSettings()">
            Salvar
        </button>
    </div>

</div>