import { Component } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, Observable, of, Subject, tap } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { CategoriaEnum } from 'src/app/enums/categoria-enum';
import { NgbDatePickerBrFormatter } from 'src/app/formatters/ngb-datepicker-br-formatter';
import { CacheService } from 'src/app/services/cache.service';
import { OperationsService } from 'src/app/services/operations.service';
import { OutrosService } from 'src/app/services/outros.service';
import { StockService } from 'src/app/services/stock.service';
import { SummaryService } from 'src/app/services/summary.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import DateUtil from 'src/app/util/date-util';
import EventUtil from 'src/app/util/event.util';
import NumberUtil from 'src/app/util/number-util';
import StaticParams from 'src/app/util/static-params';
import { ModalSimpleAddOutrosComponent, modalSimpleAddOutrosOptions } from '../modal-simple-add-outros/modal-simple-add-outros.component';


export const modalSimpleOperationsOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-simple-operation-dialog' };

export interface TickerOption {
    id: string;
    name: string;
    value: string;
    mandatory: boolean;
    label: string;
    ticker: string;
}

@Component({
    selector: 'app-modal-simple-operation',
    templateUrl: './modal-simple-operation.component.html',
    styleUrls: ['./modal-simple-operation.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDatePickerBrFormatter }
    ],
    standalone: false
})


export class ModalSimpleOperationComponent {

  public operationRow: any = {}
  public newAsset: any = {}
  public btnLoading: boolean = false
  public isDarkMode: boolean = false
  public events: any = EventUtil.operationsEventsWithAlias()
  public currencys: any = Object.keys(StaticParams.currencySymbols)
  public categorias: any = Object.values(CategoriaEnum)
  public categoriaEnum = CategoriaEnum
  public categoriaSelecionada: string
  public outrosAtivos: Array<any>
  public ativosSelect: Array<any>
  public tickers: Array<any>
  public addWithMarketCod: boolean = false
  public isMarketCodMandatory: boolean = false
  public selectedOtherInfo: any = {}
  public inputOptions$: Observable<TickerOption[]>;
  public tickerInput$ = new Subject<string>();
  public tickersLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _operationsService: OperationsService,
    private _toastr: ToastrService,
    private _summaryService: SummaryService,
    private _outrosService: OutrosService,
    private _cacheService: CacheService,
    private _viewOptionsService: ViewOptionsService,
    private _stockService: StockService,
    private _spinnerService: NgxSpinnerService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode)
  }

  getCategoryPlaceHolder() {
    if (this.categoriaSelecionada == CategoriaEnum.cvm) {
      return "CNPJ do Fundo"
    } else if (this.categoriaSelecionada == CategoriaEnum.opcoes) {
      return "Código da opção"
    } else {
      return "Digite o ativo"
    }
  }

  getOutrosAtivos() {
    this._spinnerService.show()

    this._outrosService.getOutros().subscribe({
      next: async (outrosData) => {
        this.outrosAtivos = outrosData
        this._cacheService.setCache({ 'outrosAtivos': outrosData })
        this._spinnerService.hide()
      },
      error: (err) => {
        this._spinnerService.hide()
      }
    })
  }

  getTickers() {
    this._spinnerService.show()

    const tickersStorage = JSON.parse(localStorage.getItem('tickers')) || false
    const dateNow = new Date().toISOString()

    if (tickersStorage) {
      const updateTime = new Date(tickersStorage.response_time)
      const hoursToAdd = 3 * 60 * 60 * 1000
      updateTime.setTime(updateTime.getTime() + hoursToAdd)

      if (dateNow < updateTime.toISOString()) {
        this.tickers = tickersStorage.list
        this._spinnerService.hide()
        return
      }
    }

    this._stockService.getTickers().subscribe({
      next: async (tickers) => {
        this.tickers = tickers
        localStorage.setItem('tickers', JSON.stringify({'list': tickers, 'response_time': dateNow}))
        this._spinnerService.hide()
      },
      error: (err) => {
        this._spinnerService.hide()
      }
    })
  }

  async handleCategories(forceReloadAssets: boolean = false) {
    this.isMarketCodMandatory = false
    this.addWithMarketCod = false
    this.ativosSelect = new Array()

    if (!forceReloadAssets) {
      this.operationRow.ativo = null
    }

    if (this.categoriaSelecionada == CategoriaEnum.rfixa || this.categoriaSelecionada == CategoriaEnum.outros) {
      this.outrosAtivos = this._cacheService.getCache('outrosAtivos')
      if (!this.outrosAtivos || forceReloadAssets) {
        this.outrosAtivos = null
        this.getOutrosAtivos()
      }

      while (this.outrosAtivos == null) {
        await new Promise(resolve => setTimeout(resolve, 1000))
      }

      this.operationRow.qtd = null

      if (this.categoriaSelecionada == CategoriaEnum.rfixa) {
        this.outrosAtivos.forEach(ativo => {
          if (ativo.in_auto_update) {
            this.ativosSelect.push(ativo)
          }
        });
      } else {
        this.outrosAtivos.forEach(ativo => {
          if (!ativo.in_auto_update) {
            this.ativosSelect.push(ativo)
          }
        });
      }
    } else {
      if (!this.tickers) {
        this.getTickers()
      }

      while (this.tickers == null) {
        await new Promise(resolve => setTimeout(resolve, 1000))
      }

      const enumIndex = Object.values(CategoriaEnum).indexOf(this.categoriaSelecionada as unknown as CategoriaEnum)
      const tickerKey = Object.keys(CategoriaEnum)[enumIndex]
      this.ativosSelect = this.tickers[tickerKey]
      this.inputOptions$ =
          this.tickerInput$.pipe(
              distinctUntilChanged(),
              tap(() => this.tickersLoading = true),
              switchMap(term => this.getTickersByTerm(term).pipe(
                  catchError(() => of([])), // empty list on error
                  tap(() => this.tickersLoading = false)
              ))
          );
    }
  }

  handleOtherAsset() {
    if (this.operationRow.ativo) {
      this.selectedOtherInfo = this.ativosSelect.find(ativo => ativo.ativo == this.operationRow.ativo)
    }
  }

  handleAsset() {
    this.isMarketCodMandatory = false

    if (this.categoriaSelecionada == CategoriaEnum.internacionais && 'mandatory' in this.operationRow.ativo && this.operationRow.ativo['mandatory']) {
      this.addWithMarketCod = true
      this.isMarketCodMandatory = true
    }
    if(this.categoriaSelecionada != CategoriaEnum.opcoes){
      // para opcoes eh um input simples e o usuario digita o codigo
      this.operationRow.ativo = this.operationRow.ativo['ticker']
    }
  }

  convertObjectToValid() {
    let objectToAPI = { ...this.operationRow }
    if (objectToAPI.date && objectToAPI.evento && objectToAPI.corretora && objectToAPI.ativo) {
      if (!this.addWithMarketCod && this.categoriaSelecionada == CategoriaEnum.internacionais) {
        objectToAPI.ativo = objectToAPI.ativo.split(":")[1]
      }
      objectToAPI.corretora = StaticParams.verificaCorretora(objectToAPI.corretora)
      objectToAPI.qtd = NumberUtil.usFormat(objectToAPI.qtd)
      objectToAPI.preco = NumberUtil.usFormat(objectToAPI.preco)
      objectToAPI.taxas = NumberUtil.usFormat(objectToAPI.taxas)
      objectToAPI.irrf = NumberUtil.usFormat(objectToAPI.irrf)
      objectToAPI.date = DateUtil.brToUsFormat(objectToAPI.date)
      return objectToAPI;
    } else {
      return false;
    }
  }

  addOperation() {
    this.btnLoading = true;
    const operation = this.convertObjectToValid()

    if (operation) {
      const data = new Array()
      data.push(operation)

      this._operationsService.add(data).subscribe({
        next: (x) => {
          this._toastr.success('Operação adicionada com sucesso!')
          this._summaryService.updateSummaryBehavior()
          this.activeModal.close(1)
        },
        error: (err) => {
          this.btnLoading = false
        }
      })
    } else {
      this._toastr.error('Erro no preenchimento dos dados, verifique os campos preenchidos!');
      this.btnLoading = false
    }
  }


  resetOperation() {
    this.operationRow = {
      "ativo": null,
      "date": null,
      "evento": null,
      "qtd": null,
      "preco": null,
      "taxas": null,
      "corretora": null,
      "irrf": null,
      "moeda": null,
      "observacao": null
    }

    this.isMarketCodMandatory = false
    this.addWithMarketCod = false
    this.selectedOtherInfo = {}
  }

  openModalAddSimple() {
    this.activeModal.close()
    const modalRef = this._modalService.open(ModalSimpleAddOutrosComponent, modalSimpleAddOutrosOptions)
    modalRef.componentInstance.categoriaSelecionada = this.categoriaSelecionada
    modalRef.componentInstance.operationRow = this.operationRow
    modalRef.componentInstance.outrosAtivos = this.outrosAtivos
  }

  onDatePickerChange(ngbDate) {
    this.operationRow.date = DateUtil.addTrailingZero(ngbDate.day) + "/" + DateUtil.addTrailingZero(ngbDate.month) + "/" + ngbDate.year
  }

  private getTickersByTerm(term: string): Observable<TickerOption[]> {
    // funcao pronta para usar em outras categorias, mas usamos somente em CVM por enquanto para
    // reduzir a qtd de ativos no select
    let tickers;
    if (term) {
      tickers = this.ativosSelect.filter(ticker => ticker['ticker'].toLowerCase().includes(term.toLowerCase())).slice(0, 30)
    }else{
      tickers = this.ativosSelect
      if(this.categoriaSelecionada == CategoriaEnum.cvm){
        tickers = tickers.slice(0, 30)
      }
    }
    let tickerOptions = tickers.map(ticker => {
      return {
        id: ticker['ticker'],
        label: ticker['ticker'],
        ticker: ticker['ticker'],
        name: ticker['ticker'],
        value: ticker['ticker'],
        mandatory: ticker['mandatory'],
      }
    });
    return of(tickerOptions).pipe();
  }
}