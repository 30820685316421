<div class="modal-content" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default' ">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-notification">Adicionar novo ativo</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span class="text-white" aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body py-3 text-center text-white">
        <div class="col-md-12">
            <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Nome do Ativo</h5>
            <input [disabled]="isUpdatingAsset" maxlength="20" type="text" class="asset-upper" id='operation-edit-ativo' name='ativo' [(ngModel)]="newAsset.ativo">

            <h5 *ngIf="categoriaSelecionada != categoriaEnum.rfixa" class="mt-4 text-gray"
                triggers="mouseenter:mouseleave">Classe</h5>
            <ng-select *ngIf="categoriaSelecionada != categoriaEnum.rfixa" class="select-option" [clearable]="false"
                name="setor" [(ngModel)]="newAsset.classe">
                <ng-option *ngFor="let classeOptions of newAssetClassOptions" [value]="classeOptions">{{ classeOptions
                    }}</ng-option>
            </ng-select>

            <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Tipo de Ativo</h5>
            <ng-select class="select-option" [clearable]="false" name="setor" [(ngModel)]="newAsset.setor">
                <ng-option *ngFor="let setorOptions of newAssetSetorOptions" [value]="setorOptions">{{ setorOptions
                    }}</ng-option>
            </ng-select>

            <div class="col-md-12">
                <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">Índice</h5>
                <ng-select class="select-option" (change)="handleIndexes()" [clearable]="false" name="subsetor" [(ngModel)]="newAsset.subsetor">
                    <ng-option *ngFor="let subsetorOptions of newAssetSubsetorOptions" [value]="subsetorOptions">{{
                        subsetorOptions }}</ng-option>
                </ng-select>
            </div>

            <div *ngIf="categoriaSelecionada == categoriaEnum.rfixa" class="col-md-12">
                <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">% índice (opcional) 
                <span ngbTooltip="Preencha com o % do índice. Exemplo: para um investimento de 110% do CDB, preencha com 110"
                ><i class="fas fa-question-circle"></i></span></h5>
                <input [disabled]="['PRE', 'IPCA'].includes(newAsset.subsetor) || newAsset.annual_additional_percentage != null"
                    type="number" id='operation-edit-ativo' name='ativo' [(ngModel)]="newAsset.index_percentage">
            </div>

            <div *ngIf="categoriaSelecionada == categoriaEnum.rfixa" class="col-md-12">
                <h5 class="mt-4 text-gray" triggers="mouseenter:mouseleave">%a.a. adicional {{ newAsset.subsetor !=
                    'PRE' ? '(opcional)' : '' }}
                <span ngbTooltip="Preencha com o rendimento % ao ano do investimento. Exemplo: para um investimento prefixado de 15%a.a, preencha com 15 ou para um investimento de IPCA + 10%, preencha com 10"
                ><i class="fas fa-question-circle"></i></span></h5>
                <input [disabled]="newAsset.index_percentage != null" type="number" id='operation-edit-ativo'
                    name='ativo' [(ngModel)]="newAsset.annual_additional_percentage">
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary mr-auto" (click)="resetNewAsset()">Limpar</button>
        <button type="button" class="btn btn-link text-white" data-dismiss="modal"
            (click)="closeModal(true)">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="addOutroAtivo()" [disabled]="btnLoading">
            <i class="fa fa-spinner fa-spin" *ngIf='btnLoading'></i>
            Salvar
        </button>
    </div>

</div>