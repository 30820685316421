/* src/app/components/modal-multi-investidor/modal-multi-investidor.component.scss */
td {
  font-size: small;
}
input {
  width: 100%;
}
.unmarked {
  color: rgb(230, 230, 230);
}
::ng-deep .modal-multi-investidor .modal-dialog {
  max-width: 60%;
  min-width: 360px;
}
/*# sourceMappingURL=modal-multi-investidor.component.css.map */
