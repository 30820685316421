import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';
import { GoogleAnalyticsService } from 'src/app/services/googleanalytics.service';


export const modalPixOptions: NgbModalOptions = { backdrop: 'static', windowClass: "modal-pix" }

@Component({
    selector: 'app-modal-pix',
    templateUrl: './modal-pix.component.html',
    styleUrls: ['./modal-pix.component.scss'],
    standalone: false
})
export class ModalPixComponent implements OnInit, OnDestroy {

  public isDarkMode: boolean = false
  public modalProps: any = {}
  public qr_code: string = ''
  public qr_code_base64: any = ''
  public expira_em: string = ''
  public description: string = ''
  public transaction_amount: string = ''
  public id_pgto_mp: string = ''
  public timeLeft: number = 300
  private intervalVerifyPayment: number;

  constructor(
    private _viewOptionsService: ViewOptionsService,
    public activeModal: NgbActiveModal,
    private _sanitizer: DomSanitizer,
    private _toastr: ToastrService,
    private _paymentService: PaymentService,
    private _userService: UserService,
    private _spinnerService: NgxSpinnerService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);
    this.getPixInformation()
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalVerifyPayment)
  }

  getPixInformation() {
    if (!(this.modalProps.data.point_of_interaction)) {
      this._toastr.error('Erro ao gerar o pagamento! Tente novamente mais tarde.')
      this.activeModal.close(false)
      return
    }
    this.description = this.modalProps.data.description
    this.transaction_amount = this.modalProps.data.transaction_amount
    this.qr_code = this.modalProps.data.point_of_interaction.transaction_data.qr_code
    this.qr_code_base64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.modalProps.data.point_of_interaction.transaction_data.qr_code_base64)
    this.expira_em = this.modalProps.data.date_of_expiration
    this.id_pgto_mp = this.modalProps.data.id

    this.startTimer()
  }

  CopyToClipboard(id) {
    let r = document.createRange();
    r.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this._toastr.info('Copiado para área de transferência.')
  }

  startTimer() {
    const totalTime = this.timeLeft
    this.intervalVerifyPayment = window.setInterval(() => {
      if (this.timeLeft > 0) {
        let timePassed = totalTime - this.timeLeft
        let restZero = timePassed % 5
        if (timePassed == 20 || (timePassed > 20 && restZero == 0) || (this.timeLeft == 1)) {
          this.identifyPayment()
        }

        this.timeLeft--
      } else {
        this.timeLeft = 0
        window.clearInterval(this.intervalVerifyPayment)
      }
    }, 1000)
  }

  getTimeAsMinutesAndSeconds() {
    return '0' + Math.floor(this.timeLeft / 60) + ':' + ('0' + Math.floor(this.timeLeft % 60)).slice(-2)
  }

  identifyPayment(auto: boolean = true) {
    if (!auto) {
      this._spinnerService.show()
    }

    this._paymentService.linkPayment(this.id_pgto_mp, true).subscribe({
      next: (result) => {
        if (result['message'] != 'Aguardando pagamento') {
          this._userService.getUser().subscribe({
            next: (user) => {
              this._authService.setSession(user)
              this._toastr.info(`Pagamento identificado com sucesso. Aguarde enquanto recarregamos a página.`)
              this._googleAnalyticsService.trackEvent('CONFIRM_PIX_PAYMENT', 'Confirmar pagamento PIX para contratação de plano', 'PLANO')
              setTimeout(() => {
                this.document.location.href = '/carteira'
              }, 3000);
            },
            error: (err) => {
              this._spinnerService.hide()
            }
          })
        } else {
          if (!auto) {
            this._toastr.info(`Pagamento não identificado. Caso já tenha efetuado o pagamento, faça o vínculo manual através do menu "Minha Conta".`);
          }
        }

        if (!auto) {
          this._spinnerService.hide()
        }
      },
      error: (error) => {
        if (!auto) {
          this._spinnerService.hide()
        }
      }
    });
  }

  closePaymentModal() {
    const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
    modalRef.componentInstance.modalProps = {
      type: 'confirm',
      title: 'Pagamento PIX em andamento',
      okText: 'Sim',
      cancelText: 'Cancelar',
      message: `
      Caso a janela seja fechada, o pagamento via PIX não será identificado automaticamente, e será necessário fazer a atribuição manual mais tarde.

      Deseja continuar?`,
    }
    modalRef.result.then((result) => {
      if (result.type) {
        this.activeModal.close(false)
      }
    })
  }
}
