<div class="modal-content text-white" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default'">

    <div class="modal-header">
        <h4 class="text-white">{{ modalProps.title }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePaymentModal()">
            <span class="" aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body text-center mt-0 pt-0">

        <div> {{ description }} </div>
        <div> R$ {{ transaction_amount | number: '1.2-2' }} </div>

        <div class="mt-2"> Código de referência:</div>
        <div> {{ id_pgto_mp }} </div>

        <img class="qr-code-base64 mt-2" [src]="qr_code_base64">

        <div class="mt-2" style="font-weight: 600;"> Chave PIX copia e cola:</div>
        <div id="qr-code" class="qr-codeoverflow-wrap" style="font-size: 10px;">
            {{ qr_code }}
        </div>
        <div class="btn btn-sm btn-secondary mt-1" (click)="CopyToClipboard('qr-code')">
            Copiar
        </div>

        <div class="mt-3">
            <div style="font-weight: 600;"> Status:</div>
            <div *ngIf="timeLeft > 0">           
                Aguarde enquanto identificamos o pagamento.
                <div>
                    {{ getTimeAsMinutesAndSeconds() }} minuto(s) restantes
                </div>
            </div>
            <div *ngIf="timeLeft == 0" (click)="identifyPayment(false)" class="btn btn-sm btn-secondary">
                Confirmar pagamento
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal" (click)="closePaymentModal()">
            {{ modalProps.cancelText }}
        </button>
        <button type="button" class="btn btn-primary" (click)="closePaymentModal()">
            {{ modalProps.okText }}
        </button>
    </div>

</div>