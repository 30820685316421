<div class="modal-content">
    <div class="modal-header justify-content-center pb-0">
        <h2 class="modal-title">Multi Investidor</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left pt-5 overflow-auto">

        <div class="table-responsive pl-1 pr-1">
            <table class="tb-nowrap tb-striped tb-border table-hover text-center">

                <thead class="thead-light">
                    <th [ngClass]="dynamicCss.tablePadding('1')">
                        #
                    </th>
                    <th [ngClass]="dynamicCss.tablePadding('1')">
                        Investidores
                    </th>
                    <th [ngClass]="dynamicCss.tablePadding('1')">
                        Opções
                    </th>
                </thead>

                <tbody>
                    <tr *ngFor="let row of investidorWallets; let i = index">
                        <td style="width: 20px;" class="">
                            {{ i+1 }}
                        </td>
                        <td class="">
                            <span [hidden]="!row.edit">
                                <input class="text-center" [(ngModel)]="row.nome">
                            </span>
                            <span [hidden]="row.edit" class="">{{ row.nome }}</span>
                        </td>
                        <td style="width: 50px;" [ngClass]="dynamicCss.tablePadding('1')">
                            <button [hidden]="row.edit || isActiveInvestidorWallet(row.id)" ngbTooltip="Selecionar"
                                class="btn btn-simple border btn-sm" (click)="selectInvestidor(i)">
                                <i class="fas fa-check unmarked"></i>
                            </button>
                            <button [hidden]="row.edit || !isActiveInvestidorWallet(row.id)" ngbTooltip="Selecionado"
                                class="btn btn-simple border btn-sm">
                                <i class="fas fa-check text-green"></i>
                            </button>
                            <button [hidden]="row.edit" ngbTooltip="Editar"
                                class="btn btn-simple border btn-sm" (click)="editInvestidor(i)">
                                <i class="fas fa-user-edit"></i>
                            </button>
                            <button *ngIf="i!=0 && !isActiveInvestidorWallet(row.id)" [hidden]="row.edit"
                                ngbTooltip="Excluir" (click)="confirmDeleteInvestidor(i)"
                                class="btn btn-simple border btn-sm">
                                <i *ngIf="deleteLoading != i" class="fas fa-trash-alt text-red"></i>
                                <i *ngIf="deleteLoading == i" class="fa fa-spinner fa-spin"></i>
                            </button>
                            <button *ngIf="i==0 || isActiveInvestidorWallet(row.id)" [hidden]="row.edit"
                                ngbTooltip="Excluir" (click)="deleteInvestidorDefault(i)"
                                class="btn btn-simple border btn-sm">
                                <i class="fas fa-trash-alt unmarked"></i>
                            </button>
                            <button [hidden]="!row.edit" ngbTooltip="Salvar Edição"
                                class="btn btn-simple border btn-sm" (click)="saveInvestidor(i)">
                                <i class="fas fa-save"></i>
                            </button>
                            <button [hidden]="!row.edit" ngbTooltip="Cancelar Edição"
                                class="btn btn-simple border btn-sm" (click)="cancelEditInvestidor(i)">
                                <i class="fas fa-ban"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="createNewInvestidor()"
            ngbTooltip="Novo Investidor">
            &nbsp;<i class="fas fa-plus-square pt-1"></i>&nbsp;
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close(false)">
            Fechar
        </button>
    </div>
</div>