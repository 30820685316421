import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from 'src/app/services/cache.service';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { WalletService } from 'src/app/services/wallet.service';

export const modalTableOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-table' };

@Component({
    selector: 'app-modal-table',
    templateUrl: './modal-table.component.html',
    styleUrls: ['./modal-table.component.scss'],
    standalone: false
})
export class ModalTableComponent implements OnInit {

  public modalProps: any = {};
  public colValues: any = []
  public colKeys: any = []
  public resultDecimals = []
  public btnOptions = []
  public userEventsLocal = {}

  constructor(
    private _walletService: WalletService,
    public activeModal: NgbActiveModal,
    public dynamicCss: DynamicStyleService,
    private _cacheService: CacheService,
  ) { }

  ngOnInit(): void {
    this.modalProps.title = this.modalProps.title || "Confirmação"
    this.modalProps.data = this.modalProps.data || []
    this.btnOptions = this.modalProps.btnOptions || []
    this.userEventsLocal = this._cacheService.getCache('eventsNewStatus') || {}
    if (this.modalProps.data.length) {
      this.loadTable()
    }
  }

  loadTable() {
    this.colValues = this.modalProps.colValues || Object.keys(this.modalProps.data[0])
    this.colKeys = this.modalProps.colKeys || Object.keys(this.modalProps.data[0])

    let resultKeys = []
    for (let i in this.colKeys) {
      let key = this.colKeys[i].split('|')
      resultKeys.push(key[0])
      this.resultDecimals.push(key[1] || 2)
    }
    this.colKeys = resultKeys
  }

  numberValueToString(value: any, pipeProps) {
    if (typeof value === 'number') {
      if (this.resultDecimals[pipeProps] == '%') {
        return (value * 100).toFixed(2).replace('.', ',') + "%"
      }
      if (this.resultDecimals[pipeProps] > 4) {
        return value.toString().replace('.', ',')
      }
      return value.toFixed(this.resultDecimals[pipeProps] || 2).replace('.', ',')
    }
    if (value && value.indexOf('-') >= 0 && value.split('-').length == 3 && value.length == 10) {
      return value.split('-').reverse().join('/')
    }
    return value
  }

  selectRow(row) {
    this.activeModal.close(row)
  }

  addOperation(row) {
    let result: any = {}
    result.option = 'operation'
    result.response = row
    result.request = this.modalProps.request
    this.activeModal.close(result)
  }

  addEarning(row) {
    let result: any = {}
    result.option = 'earning'
    result.response = row
    result.request = this.modalProps.request
    this.activeModal.close(result)
  }

  done(row) {
    if (this.userEventsLocal[row.hash]) {
      this.userEventsLocal[row.hash]['done'] = true
    } else {
      this.userEventsLocal[row.hash] = {}
      this.userEventsLocal[row.hash]['done'] = true
    }

    this.userEventsLocal[row.hash].hash = row.hash
    let changedEvent = this.userEventsLocal[row.hash]

    this._cacheService.setCache({ 'eventsNewStatus': this.userEventsLocal })
    this._walletService.addEventsNewsOptions([changedEvent]).subscribe(walletData => { })
    this._walletService.calcEventsNews(null)
  }

  undone(row) {
    if (this.userEventsLocal[row.hash]) {
      this.userEventsLocal[row.hash]['done'] = false
    } else {
      this.userEventsLocal[row.hash] = {}
      this.userEventsLocal[row.hash]['done'] = false
    }
    this.userEventsLocal[row.hash].hash = row.hash
    let changedEvent = this.userEventsLocal[row.hash]

    this._cacheService.setCache({ 'eventsNewStatus': this.userEventsLocal })
    this._walletService.addEventsNewsOptions([changedEvent]).subscribe(walletData => { })
    this._walletService.calcEventsNews(null)
  }

  delete(row) {
    let result: any = {}
    result.option = 'del'
    result.response = row
    result.request = this.modalProps.request

    if (this.userEventsLocal[row.hash]) {
      this.userEventsLocal[row.hash]['delete'] = true
    } else {
      this.userEventsLocal[row.hash] = {}
      this.userEventsLocal[row.hash]['delete'] = true
    }

    this.userEventsLocal[row.hash].hash = row.hash
    let changedEvent = this.userEventsLocal[row.hash]

    this._cacheService.setCache({ 'eventsNewStatus': this.userEventsLocal })
    this._walletService.addEventsNewsOptions([changedEvent]).subscribe(walletData => { })

    this.activeModal.close(result)
  }

  setMaxModalHeigth() {
    const windowheigth = window.innerHeight
    let styles = {
      'max-height': (windowheigth - 236) + 'px',
    };
    return styles;
  }

}
