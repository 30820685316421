<div class="modal-content" [ngClass]="isDarkMode ? '' : ''">
    <div class="modal-header justify-content-center">
        <span id="text-terms-use"></span>
        <button *ngIf="aceiteTermosUso" type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left ml-1 mr-1 pt-0">
        <h2 class="text-center"> Termos de Uso, Política de Privacidade e Politica de Planos </h2>
        <h5 class="text-center"> v1.0.04 </h5>

        <div id="termos-uso-text-div">
            <h3> PARA UTILIZAR O SISTEMA WEB E/OU A PLANILHA LEIA E CONCORDE COM OS TERMOS DESTE DOCUMENTO.</h3>

            <p>
                O propósito do nosso serviço é te auxiliar no controle de seus investimentos de forma consolidada, com
                base nos dados preenchidos pelo usuário e informações de mercado.
            </p>


            <h2> Sobre a disponibilidade operacional do sistema </h2>
            <p>
                Este é um sistema em fase beta, ou seja, está em desenvolvimento e não foi totalmente concluído, com
                isso não se pode assegurar seu perfeito funcionamento em todos os momentos. Instabilidades e
                indisponibilidades podem ocorrer, porém, faremos sempre o possível para reestabelecer qualquer
                anormalidade o mais rápido possível. Qualquer problema entre em contato através de nossos canais de
                suporte ou consulte nosso FAQ.
            </p>


            <h2> Sobre os dados de cálculos e garantia das informações financeiras</h2>
            <p>
                Este é um serviço com propósito informativo, não podemos assegurar que todas as informações de
                cálculo
                seja correto, cabe ao usuário (ou seja, você) verificar se todas as informações foram inseridas e
                calculadas corretamente, caso encontre informações incorretas ou incompletas, entre em contato com
                nosso
                suporte e relate o problema que iremos avaliar o relato do ocorrido e possíveis correções no sistema
                poderão ser implementadas.
            </p>
            <p>
                Todos os dados financeiros informados pelos usuários são salvos em nosso sistema com o identificador
                do
                usuário anonimizado, impedindo assim a correlação entre dados financeiros e dados pessoais do
                usuário.
            </p>

            <h2> Sobre armazenamento e uso dos dados pessoais </h2>
            <p>
                Ao utilizar os nossos recursos digitais, VOCÊ consente que trataremos seus dados pessoais conforme
                descrito nesta Política de Privacidade. Ela também informa como VOCÊ pode, em relação aos seus dados
                pessoais, exercer seus Direitos de acessar, consultar, atualizar, excluir, entre outros previstos
                pela
                Lei Geral de Proteção de Dados.
            </p>
            <p>
                A Lei Geral de Proteção de Dados (Lei 13.709/18) estabelece regras no armazenamento e tratamento de
                Dados Pessoais por empresas, diante de constantes abusos e irregularidades detectados ao longo dos
                últimos anos, desde que os Dados Pessoais começaram a apresentar grande importância para os
                negócios.
            </p>
            <p>
                Esta Política de Privacidade se limita ao tratamento de dados realizado pela dlombelloplanilhas e
                não se
                aplica a aplicativos e sites de terceiros que, por ventura, VOCÊ venha a utilizar, inclusive
                qualquer
                aplicativo ou site vinculado aos nossos serviços. Portanto, é muito importante que VOCÊ leia os
                termos e
                políticas de aplicativos e sites de terceiros antes de clicar em qualquer link.
            </p>
            <p>
                A dlombelloplanilhas não se responsabiliza pela inconformidade dos termos de uso ou das ações de
                terceiros em relação à legislação de proteção e tratamento de dados vigente.
            </p>
            <p>
                Deixamos claro que todo tratamento de dados pessoais realizado pela dlombelloplanilhas obedece
                estritamente aos princípios da Necessidade e da Finalidade, utilizando somente o mínimo de Dados
                Pessoais necessário ao seu fim.
            </p>
            <p>
                Caso a dlombelloplanilhas venha a ser instada por órgão público ou ordem judicial a revelar qualquer
                informação ou documento do usuário, apenas será fornecida a parcela minimamente necessária para o
                cumprimento da requisição.
            </p>
            <p>
                Quando houver alteração ou atualização desta Política, o usuário deverá consentir com os novos
                termos
                para que mantenha acesso ao site e demais plataformas da dlombelloplanilhas.
            </p>
            <p>
                O usuário consente no tratamento de seus dados pessoais para fins de pesquisa e melhoria dos
                serviços da
                dlombelloplanilhas, em estrito atendimento ao princípio da necessidade, de acordo com o artigo 8º da
                Lei
                13.708/2018.
            </p>
            <p>
                A dlombelloplanilhas se compromete a realizar o tratamento dos dados pessoais sensíveis
                eventualmente
                fornecidos, mediante prévio, expresso e específico consentimento do titular, conforme artigo 11 da
                LGPD
                ou outra hipótese legal, salvo nas situações em que a lei dispensar tal requisito.
            </p>
            <P>
                O objetivo principal da dlombelloplanilhas em relação ao tratamento dos Dados Pessoais dos seus
                clientes
                é a manutenção de cadastros para controle de acessos e necessidades dos próprios clientes. Sempre
                que
                possível os dados serão anonimizados e/ou criptografados.
            </P>
            <br>
            <h3>A dlombelloplanilhas pode obter seus Dados Pessoais através das seguintes fontes:</h3>
            <p>
                <strong>Sites e plataformas da dlombelloplanilhas:</strong> os recursos tecnológicos oficiais da
                dlombelloplanilhas são ferramentas utilizadas para que VOCÊ conheça, contrate e utilize nossos
                serviços,
                bem como faça contato conosco.
            </p>
            <p>
                <strong>Sites de terceiros:</strong> também podemos acessar seus dados por meio de plataformas de
                terceiros,
                tais
                como: como Google, Facebook, LinkedIn, Instagram e similares, de acordo com os Termos de Uso
                informados
                por estes terceiros e consentidos por VOCÊ quando do seu cadastro nas referidas plataformas.
            </p>
            <p>
                <strong>Correio eletrônico (e-mail) e sistemas de troca de mensagens instantâneas (celular):</strong>
                também
                podemos receber seus Dados Pessoais por e-mail, telefone, apps de comunicação (WhatsApp, Telegram,
                etc),
                SMS e similares, quando VOCÊ nos contata por meio de alguma dessas ferramentas.
            </p>
            <p>
                <strong>Atendimento ao consumidor:</strong> comunicações realizadas pelo cliente através de atendimento
                nos
                canais
                de comunicação e suporte.
            </p>
            <p>
                <strong>Dados recebidos de terceiros:</strong> incluindo, mas não limitado a redes sociais e sites de
                terceiros,
                por exemplo, Google, Facebook, Instagram, Linkedin e similares, serviços de agregadores de dados,
                parceiros da dlombelloplanilhas e fontes públicas.
            </p>
            <br>
            <h3>A dlombelloplanilhas poderá obter os seguintes Dados Pessoais:</h3>
            <p>
                <strong>Informações para Contato:</strong> Inclui qualquer tipo de informação que possa facilitar nosso
                contato da
                dlombelloplanilhas com VOCÊ, incluindo seu endereço físico de correspondência, números de telefone
                celular e fixo, endereços de correio eletrônico, sites, perfis em redes sociais; entre outras
                fornecidas
                de maneira espontânea por VOCÊ.
            </p>
            <p>
                <strong>Informações técnicas sobre seus equipamentos computacionais ou dispositivos móveis:</strong>
                detalhes
                sobre o seu computador ou outro dispositivo tecnológico utilizado para acessar um de nossos sites,
                serviços ou aplicativos, incluindo o registro do endereço IP utilizado para conectar seu computador
                ou
                dispositivo à internet, incluindo sua localização geográfica, o tipo e a versão de sistema
                operacional e
                o tipo e a versão do navegador da web.
            </p>
            <p>
                <strong>Informações de redes sociais de terceiros:</strong> Tratam-se de quaisquer dados que VOCÊ
                compartilha
                publicamente em uma rede social de terceiros ou informações que fazem parte de seu perfil em uma
                rede
                social de terceiros (como o Facebook, Instagram, LinkedIn e similares) e que VOCÊ permite que a rede
                social de terceiros compartilhe com a dlombelloplanilhas. Esses dados podem incluir detalhes como
                suas
                informações básicas de conta (nome, endereço de e-mail, gênero, data de nascimento, cidade atual,
                foto
                de perfil, ID de usuário, lista de amigos e informações similares) e quaisquer outras informações ou
                atividades adicionais que VOCÊ permita que a rede social de terceiros compartilhe com a
                dlombelloplanilhas. Para saber mais sobre como as informações da rede social de terceiros são
                obtidas
                pela dlombelloplanilhasou como não compartilhar essas informações de rede social, consulte no site
                da
                própria rede social.
            </p>
            <p>
                <strong>Informações financeiras e de pagamento:</strong> compreende quaisquer dados utilizados para
                fazer uma
                compra na dlombelloplanilhas , como os dados de seu cartão de débito ou crédito ou outras formas de
                pagamento utilizadas. A dlombelloplanilhas ou nossos prestadores de serviços de processamento de
                pagamentos manuseia suas informações financeiras e de pagamento em conformidade com as leis, normas
                e os
                padrões de segurança aplicáveis. Para mais informações consulte o site da nossa prestadora de
                serviços
                de processamento de pagamentos.
            </p>

            <h2>Dados Pessoais de Crianças e Adolescentes</h2>
            <p>
                A dlombelloplanilhas não realiza, de forma consciente, tratamento de Dados Pessoais de crianças e
                adolescentes. A dlombelloplanilhas não tem controle sobre eventual interação por titulares desta
                categoria, mas assegura que se for detectada a obtenção dos referidos dados, estes serão removidos
                imediatamente de nossos registros.
            </p>

            <h2>Uso de cookies, arquivos de registros (logs) e similares</h2>
            <p>
                Cookies e alguns dados são salvos no armazenamento local do seu navegador afim de serem utilizados
                para
                melhorar o uso e a funcionalidade dos sites, sistemas e demais serviços na web da
                dlombelloplanilhas.
            </p>


            <h2>Compartilhamento dos Dados Pessoais</h2>
            <p>
                A dlombelloplanilhas se compromete em não compartilhar seus Dados Pessoais com parceiros comerciais
                ou
                prestadores de serviços sem o seu concentimento prévio. Caso isso seja necessário, iremos enviar uma
                notificação em nosso sistema web para que tal ato seja autorizado previamente.
            </p>

            <h2>Retenção e Exclusão dos Dados Pessoais</h2>
            <p>
                A dlombelloplanilhas poderá manter os Dados Pessoais pelo tempo necessário para satisfazer as
                finalidades para as quais os Dados Pessoais foram obtidos conforme descrito nesta Política, ou para
                cumprir as obrigações legais.
            </p>

            <h2>Sobre uso das planilhas Google Sheets</h2>
            <p>
                Para usar a planilha de controle disponível no Google Sheets, você deverá fazer uma cópia do arquivo
                original disponível no Google Drive de nossa conta oficial, que está disponível em nosso site, e
                deverá
                salvar em seu Google Drive pessoal. A partir desse momento, o arquivo será de sua propriedade e não
                teremos mais nenhuma forma de acesso ou controle de seu arquivo sem a sua autorização prévia.
            </p>
            <p>
                Para que seja possível executar os scripts de cálculos através de sua cópia da planilha, será
                necessário
                autorização através do próprio sistema do Google de alguns acessos a sua conta, lembrando que o
                acesso e
                uso de sua conta, será realizado pela sua cópia da planilha, e nosso sistema apenas processa, mas
                não
                armazena qualquer dado disponível em suas planilhas.
            </p>
            <p>
                Como a cópia da planilha é de sua propriedade, caso queira excluir, será necessário o próprio
                usuário
                fazer a exclusão do arquivo na pasta e da lixeira do Google Drive.
            </p>
            <p>
                VOCÊ poderá verificar a qualquer momento através do "Compartilhamento" do Google Drive os arquivos e
                as
                contas que tem acesso compartilhado a seus arquivos. Mais informações em:
                https://support.google.com/drive#topic=7000947
            </p>

            <h2>Dúvidas sobre nossa política de privacidade e tratamento de dados</h2>
            <p>
                Sempre que VOCÊ tiver alguma dúvida, sinta-se a vontade para entrar em contato através de nossos
                canais
                de suporte para que possamos te orientar e esclarecimento de dúvidas, ou acesse o FAQ em nosso site.
            </p>

            <h2>Alterações na Política de Privacidade</h2>
            <p>
                Sempre que a dlombelloplanilhas alterar a forma que realiza o tratamento dos Dados Pessoais dos
                titulares, esta Política será atualizada. Nos reservamos o direito de fazer alterações às nossas
                práticas e a esta Política a qualquer tempo, desde que mantida a conformidade com a legislação
                vigente.
                Recomendamos aos usuários, clientes e colaboradores, que consultem sempre esta Política de
                Privacidade e
                se mantenham atualizados quanto ao tratamento que vem sendo adotado pela dlombelloplanilhas aos
                Dados
                Pessoais.
            </p>

            <h2>Politica de Planos, Reembolso e Pagamentos</h2>
            <p>
                Os planos com menos de 30 dias, poderão ser solicitados o cancelamento e terão direito a reembolso
                do
                valor intergral, porém, não podemos garantir a manutenção dos dados do usuário em nosso sistema. O
                cancelamento e reembolso devem ser solicitados através do email de suporte em nosso site, devendo
                ser informados o email do titular da conta e código de pagamento a ser cancelado/reembolsado para
                analise. O prazo para análise e estorno é de até 5 dias úteis.
            </p>
            <p>
                No caso de uma nova compra ou upgrade de plano, o plano mais recente comprado será considerado como
                plano vigente, e todos os planos ainda válidos anteriormente vinculados a sua conta terão o saldo
                proporcional não utilizado acrescentado como dias extras ao vencimento do plano vigente.
            </p>
            <p>
                Um usuário que quiser trocar a conta de email já vinculada a um plano, deverá excluir o código
                vinculado
                na conta antiga e vincular esse mesmo código a uma nova conta. Um mesmo código de pagamento poderá
                ser
                vinculado a uma conta por no máximo por duas (2) vezes.
            </p>
            <p>
                Caso uma conta com plano multi investidor venha a ser vinculado um plano inferior posteriormente, ou
                perca a validade, os dados das carteiras dos investidores adicionais serão congelados e não poderão
                sofrer cadastros e atualizações, até que um novo plano multi investidor equivalente esteja válido
                novamente. Manteremos os dados dos investidores adicionais em nosso banco de dados por no máximo 1
                ano,
                em contas de multi investidores vencidas.
            </p>
            <p>
                Os planos adquiridos em nosso site são de uso imediato. Assim que a compra for concluída, o código gerado garante acesso instantâneo ao plano. 
                Caso o vínculo não ocorra automaticamente pelo QR code, ele deve ser feito manualmente pelo usuário no momento da compra. Se o usuário não tiver o código ou não achar
                em seu comprovante que é enviado por email ou mostrado no fim da compra, ele deve entrar em contato com o suporte para conseguir o mesmo. 
                Os códigos não devem ser guardados para uso posterior, pois são destinados exclusivamente ao consumo imediato.
            </p>

            <h2>Politica de notificações</h2>
            <p>
                A dlombelloplanilhas poderá enviar informações essenciais relacionadas à sua conta e atividades por meio
                do seu endereço de e-mail. Você tem controle total sobre suas preferências de notificação, podendo
                configurar como deseja receber comunicações. Suas informações são tratadas com responsabilidade,
                utilizadas apenas para fins de comunicação no sistema e não compartilhadas sem sua autorização.
            </p>

            <div *ngIf="!aceiteTermosUso" class="form-check mt-4 ml-2">
                <input class="form-check-input" type="checkbox" [(ngModel)]="checkAceitarTermosUso"
                    id="flexCheckDefault">
                <label class="form-check-label text-sm" for="flexCheckDefault">
                    Aceito os termos e condições de uso.
                </label>
            </div>
            <br>
        </div>


        <div *ngIf="aceiteTermosUso" class="mt-4">
            <strong>Os termos de uso já foram aceitos!</strong>
        </div>
    </div>

    <div *ngIf="!aceiteTermosUso" class="modal-footer">
        <button type="button" class="btn btn-primary mr-auto" (click)="aceitarTermosUso()">
            Confirmar
            <i class="fa fa-spinner fa-spin" *ngIf='btnLoading'></i>
        </button>

        <button type="button" class="btn btn-danger" (click)="logout()">
            Sair
        </button>
    </div>

    <div *ngIf="aceiteTermosUso" class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="activeModal.close(false)">
            Fechar
        </button>
    </div>

</div>