/* src/app/components/modal-simple-add-outros/modal-simple-add-outros.component.scss */
.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
::ng-deep .modal-simple-operation-dialog .modal-dialog {
  max-width: 30rem;
  font-size: 13px;
}
#operation-categoria {
  margin: auto;
  width: 335px;
}
input {
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  height: 35px;
  width: 172px;
}
input:disabled {
  color: black !important;
}
.select-option {
  width: 172px;
  margin: auto;
}
::ng-deep .ng-has-value .ng-value-container {
  display: inline-block !important;
}
.checkbox-text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
}
.asset-upper {
  text-transform: uppercase;
}
/*# sourceMappingURL=modal-simple-add-outros.component.css.map */
