/* src/app/components/modal-confirmation-dialog/modal-confirmation-dialog.component.scss */
ul {
  text-align: center;
}
.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
input {
  text-align: center;
}
h2 {
  color: #31bdbd;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #ffffff;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #ffffff;
}
::ng-deep .modal-confirmation-dialog .modal-dialog {
  max-width: 35rem;
}
/*# sourceMappingURL=modal-confirmation-dialog.component.css.map */
