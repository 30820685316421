/* src/app/components/modal-profile/modal-profile.component.scss */
ul {
  text-align: center;
}
.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
.image-profile {
  width: 60px;
  height: 60px;
  border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -webkit-border-radius: 100px 100px 100px 100px;
  box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
}
.vincular-alpha {
  margin-top: 20px;
}
.vincular-alpha .vincular-alpha-form {
  margin-top: 15px;
}
.vincular-alpha .vincular-alpha-form > div {
  margin-bottom: 7px;
}
.vincular-alpha .vincular-alpha-form > button {
  margin-left: 6px;
  padding: 7px;
}
.vincular-alpha #remover-pagamento {
  margin-bottom: 10px;
}
.text-small {
  font-size: small;
}
/*# sourceMappingURL=modal-profile.component.css.map */
