/* src/app/components/modal-simple-operation/modal-simple-operation.component.scss */
.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
::ng-deep .modal-simple-operation-dialog .modal-dialog {
  max-width: 30rem;
  font-size: 13px;
}
#operation-categoria {
  margin: auto;
  width: 335px;
}
input {
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  height: 35px;
  width: 172px;
}
.select-option {
  width: 172px;
  margin: auto;
}
::ng-deep .ng-has-value .ng-value-container {
  display: inline-block !important;
}
.checkbox-text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
}
.link-saldo {
  text-decoration: underline;
  color: #50bace;
}
.corretora-upper {
  text-transform: uppercase;
}
.operation-edit-date {
  width: 142px;
}
.op-date-calendar {
  height: 32px;
  margin-top: 2px;
}
.rf-info-header {
  padding-top: 10px;
}
.rf-info-list {
  text-align: left;
}
::ng-deep ngb-datepicker {
  transform: translate(0, 0) !important;
}
/*# sourceMappingURL=modal-simple-operation.component.css.map */
