<div class="modal-content">
    <div class="modal-header justify-content-center pb-0">
        <h2 class="modal-title">{{title}}
            <span *ngIf="pagesDataLen > 1" style="font-size: 0.7rem;">
                (pág: {{ saveDataPage +1 }} de {{ pagesDataLen }})
            </span>
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left pt-2 overflow-auto">
        <div class="d-flex justify-content-between align-items-end">
            <div class="small">*Campos obrigatórios</div>
        </div>
        <div class="small pt-1 overflow-auto jspreadsheet-maxheight" id="spreadsheet" #spreadsheet></div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close(false)">
            {{closeText}}
        </button>
        <button [disabled]="loading" type="button" class="btn btn-primary" (click)="save()">
            Salvar
        </button>
    </div>
</div>