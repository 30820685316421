<div class="modal-content">
    <div class="modal-header justify-content-center pb-0">
        <h2 class="modal-title">{{ modalProps.title }}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-left pt-2 pb-2 pl-3 pr-3 overflow-auto">

        <div class="pl-2"  *ngIf="isUndefinedAtivo">
            Ativo não encontrado em nosso banco de dados!<br>
            Verifique a digitação do mesmo ou verifique nosso FAQ sobre ativos e mercados.
        </div>

        <ul *ngIf="!isUndefinedAtivo" class="nav nav-tabs">
            <a class="nav-link" [ngClass]="activeTable == 'info' ? 'bg-blue text-white' : '' " role="button"
                (click)="toggleTable('info')">Info
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'grafico' ? 'bg-blue text-white' : '' " role="button"
                (click)="toggleTable('grafico')">Gráfico
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'news' ? 'bg-blue text-white' : '' " role="button"
                (click)="toggleTable('news')">Notícias
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'operacoes' ? 'bg-blue text-white' : '' " role="button"
                (click)="toggleTable('operacoes')">Operações
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'proventos' ? 'bg-blue text-white' : '' " role="button"
                (click)="toggleTable('proventos')">Proventos
            </a>
        </ul>

        <div *ngIf="!isUndefinedAtivo" class="pt-2">
            <div [hidden]="activeTable != 'info'">
                <app-ativo-info [portfolio]="portfolio" [stockinfo]="stockinfo" [info]="info"
                    [fundamentos]="fundamentos"></app-ativo-info>
            </div>
            <div [hidden]="activeTable != 'grafico'">
                <app-ativo-grafico [portfolio]="portfolio" [stockinfo]="stockinfo"></app-ativo-grafico>
            </div>
            <div [hidden]="activeTable != 'news'">
                <app-ativo-noticias [portfolio]="portfolio" [stockinfo]="stockinfo"></app-ativo-noticias>
            </div>
            <div [hidden]="!(activeTable == 'operacoes' || activeTable == 'proventos')">
                <div class="table-responsive pl-1 pr-1" [ngStyle]="setMaxModalHeigth()">
                    <table class="tb-nowrap tb-striped tb-border table-hover align-items-center">
                        <thead class="thead-light">
                            <th *ngFor="let col of colValues; let i = index" [ngClass]="dynamicCss.tablePadding('1')">
                                <span class="row-td-resume">
                                    {{ col }}
                                </span>
                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of table">
                                <td *ngFor="let col of colKeys; let i = index"
                                    [ngClass]="dynamicCss.formatNumbersClass(row[col], 'red', 'dark')">
                                    <span class="row-td-resume font-weight-bold">
                                        {{ numberValueToString(row[col], i) }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close(portfolio)">
            Fechar
        </button>
    </div>
</div>